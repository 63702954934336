export default {
  heading: 'Cormorant, serif',
  body: 'Cormorant, serif',
  monospace: 'Cormorant, serif',
  // display: 'Kaushan Script, cursive',
  display: 'Cormorant, serif',
  googleFonts: ['Playfair Display', 'Montserrat:100,200,300,400,500,600,700,800,900', 'Comfortaa', 'Cormorant']
  // customFamilies: ['epicscript'],
  // customUrls: ['https://www.gonation.biz/fonts/epic-font/epic-font.css']
}
