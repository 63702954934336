import buttons from './buttons'

export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    // ? ======= Nav Container Scrolled =======
    '.container': {
      backgroundColor: 'backgroundSecondary',
      padding: ['0.5rem', '0.5rem', '0.5rem', '1rem'],
      // '.logo': {
      //   display: ['block', '', '', 'none'],
      //   img: {
      //     filter: 'unset',
      //   },
      // },
      zIndex: '2000',
      '.smallNavMenu > div': {
        a: {
          color: 'white',
          letterSpacing: '0px',
          fontSize: ['0.9rem', '0.9rem', '0.9rem', '0.9rem', '1rem']
        }
      }
    },

    // ? ======= Nav Container Scrolled =======

    '.containerScrolled': {
      backgroundColor: 'backgroundSecondary',
      zIndex: '2000',
      '.smallNavMenu > div': {
        a: {
          color: 'white',
          letterSpacing: '0px',
          fontSize: ['0.9rem', '0.9rem', '0.9rem', '0.9rem', '1rem']
        }
      }
    },

    // ? ======= Desktop Small Nav Menu =======

    '.smallNavMenu': {
      '.navItem': {
        margin: ['', '', '', '0.5rem', '0.75rem'],
        a: {
          fontSize: '1rem',
          color: 'primary',
          fontWeight: '600',
          ':hover': {
            color: 'grey',
            textDecoration: 'underline'
          }
        }
      }
    },
    // ? =======  Logo  =======

    '.logoLocationContainer': {
      position: 'static',
      margin: '0rem auto 0rem 0rem'
    },

    '.logo': {
      img: {
        maxWidth: '150px',
        filter: 'unset'
      }
    },

    '.logoScrolled': {
      img: {
        // filter: 'brightness(0) invert(1)',
        maxWidth: '100px',
        height: 'auto'
      }
    },

    // ? =======  Hamburger  =======

    '.hamburger': {
      // backgroundColor: 'rgb(255 255 255 / 42%)',
      border: 'none!important'
    },

    // ? =======  Nav Menu  =======

    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['1rem', '2rem', '3rem', '3rem'],
      backgroundColor: 'black',
      width: ['90%', '', '40%', '30%'],
      '.navMenuLogo': {
        alignSelf: 'flex-start',
        img: {
          filter: 'brightness(0) invert(1)',
          maxWidth: '150px',
          height: 'auto',
          marginBottom: '2rem'
        }
        // display: 'none',
      },

      '.navItem': {
        width: '100%',
        textAlign: 'left',
        borderBottom: '1px solid white',
        paddingBottom: '0.5rem',
        transition: 'all ease-in-out 0.5s',
        a: {
          color: 'white',
          fontSize: ['1.1rem', '1.25rem', '1.25rem', '1.25rem']
        },
        ':hover': {
          paddingLeft: '1.5rem',
          borderColor: 'primary',
          a: {
            color: 'primary'
          }
        }
      },

      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto'],
        display: 'none'
      },
      '.phoneSocialContainer': {
        marginTop: '2rem',
        a: {
          textAlign: ['left'],
          padding: '0rem',
          color: 'white',
          justifyContent: 'flex-start'
        },
        svg: {
          path: {
            fill: 'white'
          }
        },
        '.smallNavHeading ': {
          textAlign: ['left'],
          fontSize: '1rem'
        },

        '.socialIconsContainer': {
          alignItems: 'flex-start',
          a: {
            padding: '0rem 0.5rem 0rem 0rem'
          }
        }
      }
    },

    // ? =======  Nav Menu opaque background  =======

    '.navBlockOpen': {
      backgroundColor: '#d3b97399'
    }
  },

  footer: {
    borderTop: 'solid 1px',
    borderColor: 'dark',
    backgroundColor: 'light',
    color: 'text',
    padding: ['1rem 2rem 4rem', '', '', '1rem 1rem 1rem 1rem'],
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'grey'
    },
    '.row': {
      alignItems: ['', '', '', 'stretch']
    },
    '.column': {
      flexGrow: '1'
    },
    '.columnLogo': {
      maxWidth: '250px',
      paddingRight: ['', '', '', '5rem'],
      margin: '0 auto',
      img: {
        transform: ['', '', '', 'rotate(-90deg)'],
        transformOrigin: ['', '', '', 'bottom']
      }
    },

    '.footerSectionHeading': {
      fontFamily: 'heading',
      fontSize: '1rem',
      color: 'primary',
      borderBottom: '1px solid',
      paddingBottom: '1rem',
      textTransform: 'uppercase',
      fontWeight: '300'
    },

    '.contactDetails-container': {
      color: 'grey'
    },

    '.multiButtonContainer': {
      border: 'none',
      a: {
        border: 'none!important',
        padding: '0.5rem 0rem',
        textTransform: 'capitalize',
        color: 'grey'
      }
    },
    '.socialContainer svg path': {
      fill: 'grey',
      color: 'grey'
    },

    '.bottomBar': {
      padding: ['', '', '', '0rem 4.5rem 0rem 0rem']
    }
  },
  ctaWidget: {},

  pageHero: {
    height: '60vh',
    h1: {
      letterSpacing: '3px',
      textTransform: 'uppercase',
      color: 'primary',
      borderTop: '3px solid',
      borderBottom: '3px solid',
      borderColor: 'primary',
      paddingBottom: '0.5rem',
      background: 'rgba(0,0,0,0.35)',
      padding: '1rem'
    }
  },

  // ? ======================
  // ? ====  Reuseables  ====
  // ? ======================

  title: {
    order: '1',
    color: 'primary',
    fontFamily: 'heading',
    fontSize: ['2rem', '', '2.5rem', '2.75rem'],
    margin: '0rem 0rem 2rem',
    textTransform: 'uppercase'
  },
  subtitle: {
    fontSize: ['1.25rem', '', '1.5rem', '2rem'],
    order: '2',
    borderBottom: '1px solid',
    width: 'fit-content',
    margin: '0rem 0rem 1.5rem',
    color: 'secondary'
  },

  text: {
    order: '3',
    opacity: '0.7'
    // color: '',
  },

  sideBySide: {
    padding: ['2rem 1rem', '', '2rem', '3rem'],
    '.content': {
      padding: ['2rem 1rem', '', '3rem'],
      position: 'relative',
      height: 'fit-content',
      backgroundColor: '#fffff3',
      margin: ['0', '0', '0', 'auto 0rem auto -10vw']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: { order: '4' },
    '.ctaButton': {
      variant: 'buttons.secondary'
    },

    '.image, img': {
      minHeight: ['50vh', '', '70vh']
      // height: ['', '', '', '90vh']
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================
  homepageInstagram: {
    '.logoAndTextContainer ': {
      // margin: '3rem 0rem 2rem',
      display: 'none'
    },
    '.iFrameContainer': {
      width: ['100%', '100%', '100%']
    }
  },
  homepageTag1: {
    mb: ['-7rem', '', '', 'unset'],
    '.hero_content_container': {
      borderLeft: 'none',
      left: '50%',
      transform: ['', '', '', 'translateX(-50%)'],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: ['transparent', '', '', 'transparent'],
      padding: ['1rem 0rem', '', '', '2rem'],
      // borderRadius: '80px',
      top: ['-7rem', '', '', 'unset'],
      left: ['0rem', '', '', '50%'],
      position: ['relative', '', '', 'absolute'],
      width: ['50%', '', '', '100%'],
      m: '2rem auto',
      '.title': {
        variant: 'customVariants.title',
        mb: '1rem'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4',
        m: '0 auto',
        background: 'white',
        '.ctaButton': {
          margin: '0',
          variant: 'buttons.primary',
          color: ['black', '', '', 'black'],
          borderColor: ['black', '', '', 'white']
        }
      }
    },
    '.muteToggle': {
      top: ['calc(50vh - 3rem)', '', '', 'unset']
    }
  },

  homepageShout: {
    padding: ['', '', '1rem'],
    flexDirection: ['column', 'column', 'row'],

    '.imageContainer': {
      width: ['100%', '100%', '40%'],
      maxHeight: ['', '', '55vh'],
      order: ['2', '', '1']
    },
    '.image': {
      padding: ['0rem', '', '1rem', '1rem'],
      objectFit: 'cover'
    },
    '.content': {
      minHeight: '250px',
      // backgroundColor: ['#f1f1f1', '', ''],
      color: 'primary',
      margin: ['', '', '1rem'],
      padding: ['1rem 4rem 1rem 1rem', '1.5rem 4rem 1.5rem 1.5rem', '2rem 5rem 2rem 2rem'],
      width: ['100%', '100%', '60%'],
      order: ['1', '', '2']
    },
    '.text': {
      lineHeight: '1.5',
      // color: 'light',
      fontSize: ['1.5rem', '', '1.6rem', '1.75rem'],
      letterSpacing: '1px',
      fontFamily: 'heading'
    },
    '.title': {
      width: ['3rem', '', '', '4rem'],
      textTransform: 'uppercase',
      // color: 'light',
      letterSpacing: '3px'
    },
    '.date': {
      // color: 'light',
      // display: 'none',
    },

    '.shoutCTA': {
      variant: 'buttons.secondary'
    }
  },

  homepageTag2: {
    padding: ['1rem', , '2rem'],
    margin: '2rem 0rem',
    // alignItems: ['', '', 'center', 'center'],
    background: 'transparent',
    '.section': {
      padding: ['1rem', '', '3rem'],
      maxWidth: 'unset',
      width: '100%'
    },

    '.title': {
      variant: 'customVariants.title',
      color: 'black',
      fontSize: ['2.5rem', '3.5rem', '4.5rem', '5.5rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: '20px!important'
    },
    '.text': {
      variant: 'customVariants.text',
      p: {
        fontSize: '20px!important'
      }
    },
    a: { order: '4' },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  homepageTag3: {
    paddingBottom: '.5rem',
    '.section': {
      '.title': {
        color: 'var(--theme-ui-colors-primary,#d3b973)',
        fontFamily: 'Cormorant,serif',
        fontSize: '2.75rem',
        margin: '0rem 0rem 2rem',
        textTransform: 'uppercase'
      }
    }
  },
  homepageBoxes: {
    padding: ['0rem 1rem 3rem', '', '0rem 2rem 5rem'],
    '.box': {
      padding: '1rem 0rem',
      width: ['100%', '', 'calc(50% - 2rem)', 'calc(33.3% - 2rem)'],
      position: 'relative',
      height: ['450px', '', '550px', ' 600px'],

      background: 'transparent',
      color: 'white',
      margin: '1rem',
      borderRadius: '0px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      '> *': {
        zIndex: '1'
      }
    },
    '.image': {
      position: 'absolute',
      // height: ['450px', '', '550px', ' 650px'],
      height: '100%',
      zIndex: '0',
      objectFit: 'cover',
      filter: 'brightness(0.8)',
      mb: '0rem'
    },
    '.title': {
      padding: '0rem 2rem',
      order: '2',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '1.75rem', '2rem', '', '2.25rem'],
      marginBottom: '1rem',
      alignSelf: 'flex-start'
    },
    '.subtitle': {
      margin: '3rem 2rem 0rem',
      padding: '1rem 0rem',

      width: 'calc(100% - 4rem)',
      textTransform: 'uppercase',
      order: '1',
      fontSize: '1.1rem',
      fontWeight: 'bold'
    },
    '.text': {
      padding: '0rem 2rem',
      order: '3',
      color: 'white',
      margin: '1rem 0rem 2rem',
      '> *': {
        color: 'white'
      }
    },
    '.ctaLink': {
      variant: 'buttons.secondary',
      margin: '0rem 2rem 3rem',
      order: '4',
      width: 'fit-content',
      borderRadius: '0px',
      textTransform: 'uppercase'
    }
  },

  homepageTag10: {
    variant: 'customVariants.sideBySide',
    '.content': {
      '.text': {
        p: {
          fontSize: '20px!important'
        }
      }
    }
  },

  homepageTag11: {
    variant: 'customVariants.sideBySide',
    '.content': {
      '.text': {
        p: {
          fontSize: '20px!important'
        }
      }
    },
    justifyContent: ['', '', 'flex-end'],
    'div.content': {
      margin: ['0rem', '', 'auto -2vw auto  0rem']
    }
  },

  homepageTag12: {
    '.text': {
      p: {
        fontSize: '20px!important'
      }
    },
    color: 'text',
    padding: '8rem 1rem',
    backgroundColor: 'backgroundSecondary',
    textAlign: 'center',
    '.section': {
      padding: '2rem',
      backgroundColor: '#ffffff70'
    },

    a: {
      '.ctaButton': {
        variant: 'buttons.secondary',
        padding: '1rem 2rem'
      }
    }
  },

  homepageTag13: {
    padding: '0rem',
    '.textContent': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      p: '2rem 2rem 0rem'
    },

    '.slick-list': {
      height: ['100px', '150px', '', '250px'],
      '.slick-track': {
        height: ['100px', '150px', '', '250px'],
        '.slick-slide': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '1rem',
          img: {
            margin: '0rem 0.75rem',
            objectFit: 'contain',
            objectPosition: 'center center'
          }
        }
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    flexDirection: ['column', 'column', 'column', 'row'],
    padding: ['1rem', , '2rem'],
    background: 'transparent',
    '.lazyload-wrapper': {
      width: ['100%', '100%', '100%', '30%']
    },
    '.image': {
      order: ['', '', '2'],
      width: ['100%', '100%', '100%', '100%'],
      maxHeight: '100vh'
    },
    '.section': {
      maxWidth: '800px'
    },
    '.content': {
      order: ['', '', '', '1'],
      border: 'none',
      borderWidth: '5px',
      padding: ['1rem 0rem', , 8],
      width: ['100%', '100%', '100%', '68%'],
      margin: ['', '', '', '0rem'],
      position: ['static', 'static', 'static', 'static']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      fontSize: '20px!important'
    },
    a: { order: '4' }
  },

  aboutSectionNavLinks: {
    h2: {
      backgroundColor: 'primary',
      width: '100%'
    },
    '.box': {
      height: '300px',
      // flexGrow: 'unset',
      boxShadow: '2px 2px 8px #333333'
    }
  },

  aboutTeam: {
    color: 'text',
    padding: ['8rem 1rem', '', '12rem 1rem'],
    backgroundColor: 'backgroundSecondary',
    textAlign: 'center',
    '.section': {
      padding: '2rem',
      backgroundColor: '#ffffff70'
    },
    h2: {
      color: 'black',
      textTransform: 'uppercase',
      fontSize: '2rem',
      letterSpacing: '2px',
      maxWidth: '600px',
      marginBottom: '2rem'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  // ? ========================
  // ? ====  team page  ====
  // ? ========================

  allServices: {
    '.menuSectionTitle': {
      fontFamily: 'display',
      fontSize: '3rem'
    },
    '.menuItemContainerImgActive, .menuItemContainer': {
      width: '100%',
      overflow: 'unset',
      margin: ['', '', '3rem 0rem']
    },

    '.menuItemInnerContainer': {
      flexDirection: ['column', '', 'row'],
      alignItems: 'flex-start',
      backgroundColor: ['', '', '']
    },

    '.menuItemContentContainer': {
      width: ['100%', '', '70%'],
      padding: ['', '', '1rem 1rem 1rem 3rem'],
      justifyContent: ['', '', 'center'],
      alignItems: ['', '', 'Flex-start'],
      flexBasis: 'unset',
      margin: ['', '', '', '0rem'],
      a: {
        variant: 'buttons.secondary',
        marginTop: '2rem'
      }
    },

    '.menuItemImageContainer': {
      width: ['100%', '', '30%'],
      maxHeight: 'unset',
      maxWidth: 'unset',
      transform: 'rotate(2deg)'
      // position: ['', '', 'relative'],
      // left: ['', '', '-1rem'],
      // top: ['', '', '-2rem'],
      // boxShadow: '2px 3px 12px grey',
    },
    '.menuItemImg': {
      border: 'solid white 0.5rem',
      paddingRight: '0px'
    },
    '.menuItemName': {
      letterSpacing: '3px',
      textTransform: 'uppercase',
      color: 'secondary',
      padding: '1rem 0',
      fontFamily: 'BW Vivant Light',
      fontSize: ['1.5rem', '', '', '2rem']
    },
    '.menuItemDescription': {
      lineHeight: '1.75',
      fontSize: '1rem'
    }
  },

  // ? ========================
  // ? ====  Services page  ====
  // ? ========================

  allServices: {
    borderLeft: ['20px solid', '25px solid', '30px solid', '50px solid'],
    maxWidth: 'unset',
    '.menuSectionTitle': {
      fontFamily: 'heading',
      textTransform: 'uppercase',
      color: 'primary',
      fontSize: ['1.5rem', '2rem', '2.5rem', '3rem'],
      textAlign: 'left',
      margin: '0rem 0rem 1.5rem',
      padding: ['0.5rem', '0.5rem 1.5rem'],
      borderBottom: '1px solid',
      borderColor: 'primary',
      width: '100%',
      paddingLeft: '0.5rem'
    },
    '.menuItemInnerContainer': {
      margin: '0rem'
    },
    '.menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuItemName': {
      fontFamily: 'heading',
      color: 'dark',
      fontWeight: 'normal'
    },
    '.menuItemPrice': {
      color: 'secondary',
      fontFamily: 'heading',
      fontWeight: 'bold'
    },
    '.variantContainer': {
      color: '#be9e45'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsCTA:first-child': {
      display: 'none'
    },
    '.albumTitle': {
      variant: 'buttons.secondary'
    }
    // '.albumsContainer': {
    //   display: 'none',
    // },
  },

  // ? ==========================================
  // ? ====  Featured Artisits Gallery page  ====
  // ? ==========================================

  featuredArtists: {
    '.albumTitle': {
      variant: 'buttons.secondary'
    },
    '.backButton': {
      variant: 'buttons.secondary'
    },
    '.albumsCTA': {
      width: ['100%', 'calc(50% - 2rem)']
    },
    '.albumImage': {
      width: ['50%', '', '33.3%']
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    },
    '.eventItemBtn': {
      color: 'black',
      border: 'solid 1px black'
    },
    '.modalContentContainer': {
      '.eventDate, .eventMonth': {
        color: 'white'
      }
    },
    '.modalEventTime': {
      color: 'white'
    },
    '.modalEventDescriptionContainer': {
      color: 'white'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    backgroundColor: '#f5f0e2',
    '.title': {
      fontFamily: 'display',
      fontSize: '3rem',
      color: 'secondary',
      borderBottom: 'solid 1px',
      paddingBottom: '0.5rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1.25rem',
      opacity: '0.8'
    },
    '.text': {
      fontStyle: 'italic',
      marginBottom: '3rem',
      color: 'secondary',
      opacity: '0.7'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid',
        borderColor: 'dark',
        fontFamily: 'body',
        margin: '1rem 0rem',
        color: 'dark',
        '::placeholder': { color: 'dark' }
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid',
        borderColor: 'dark',
        fontFamily: 'body',
        margin: '1rem 0rem',
        color: 'dark',
        '::placeholder': { color: 'dark' }
      },
      '.submitBtn': {
        borderRadius: '0px'
      }
    }
  },

  locationMap: {
    order: '2',
    h3: {
      textTransform: 'uppercase',
      color: '#EAE3D1'
    },
    '.content_container': {
      backgroundColor: 'secondary'
    }
  },

  teamMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],

    '.boxesHeading': {
      display: 'none'
    },
    '.box': {
      maxWidth: ['', '50%', '30%']
    },
    '.box .juliette-galeas': {
      display: 'none'
    },
    '.imageContainer': {
      img: {
        objectPosition: 'top'
      }
    }
  },
  teamPage: {
    '::before': {
      // this gives the nav a background without having a hero.
      content: ['none', 'none', 'none', "''"],
      display: 'flex',
      width: '100%',
      height: ['100px', '', '', '125px'],
      mb: '1rem',
      backgroundColor: 'background'
    },
    '.imageContainer': {
      width: ['100%', '', '50%']
    },
    '.contentRow': {
      padding: '2rem',
      width: ['100%', '', '50%'],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    maxWidth: '1200px',
    m: 'auto'
  }
}
